<template>
   <div style="width:90%;margin:0 auto;">
        <div style="max-width:1166px;position: relative;margin:0 auto;">
            <div style="max-width:1166px;margin-top:100px;margin-bottom: 200px;">
                <!-- 背景 -->
                <div style="width:100%;height:100%;position:absolute;padding-top:15.437%;">
                    <div style="position:absolute;margin:0 0 0 0;width:100%;height:96.6%;background-color:rgb(255,254,245);z-index:-2;"></div>
                    <div style="position:absolute;margin:0 0 0 98.628%;width:1.373%;height:96.6%;background-color:rgb(55,136,148);z-index:-1;"></div>
                </div>
                <div style="position:relative;">
                    <img :src="Image.TopBackgroundCard" style="position:absolute;z-index:-3;">
                    
                </div>
                <div style="position:relative">
                    <div style="position:absolute; margin: 26.586% 0 0 0;width:100%; text-align:center;">
                        <h1 style="font-size: 90pt; font-family: 'Gen'; color: rgb(95,185,205);height:94px;">
                            {{ UserName }}
                        </h1>
                    </div>
                </div>
                <div style="position:relative">
                    <div style="position:absolute; margin: 42.38% 0 0 0; width:100%; text-align:center;;">
                        <h1 style="font-size: 60pt; font-family: 'jf'; color: black;">面相檢測結果</h1>
                    </div>
                </div>
                <div style="padding: 53.786% 26.5% 0 25.128%;">
                    <div style="position:relative">
                        <img style="position:absolute;" :src="Image.TitleBackgroundImg">
                        <div style="position:relative;">
                            <div class="FaceTitleText" style="position:absolute;max-width:510px;width:100%;left:50%;transform: translateX(-50%);">
                                <h1 style="margin:0; color: white; font-size: 50pt; font-family: 'Gen';text-align:center;">
                                    {{ FaceTitle }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="padding:12.521% 8.233% 0% 6.861%;">
                    <div style="position:relative;">
                        <img :src="Image.mirror" style="position:absolute;">
                    </div>
                </div>
                <div style="padding:97.427% 8.233% 0% 6.861%;margin-bottom:13.722%;">
                    <h1 style="font-size: 35pt;font-family:'jf'; color: black;margin:0;text-align:justify;">
                        {{ Personality }}
                    </h1>
                </div>
                <div style="padding:0 28.13% 0 26.758%">
                    <div style="position:relative;">
                        <img :src="Image.TitleHealthWarning" style="position:absolute;"><!--健康警訊-->
                    </div>   
                </div>
                
                <div style="width: 100%; position: relative; padding: 12.864% 7.952% 0 6.361%;"><!--疾病列表-->
                    <div class="accordion accordion-flush" style=" margin-bottom:56px;" v-for="(item, index) in Disease" :key='index'>
                        <div class="accordion-item" style="background-color:transparent;">
                            <h2 class="accordion-header">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#a'+index"
                                    aria-expanded="false"
                                    :aria-controls="'a'+index"
                                    style="
                                        background-image: url(/img/TestResultsImage/other/title_disease_base_map.svg);
                                        background-repeat:no-repeat; background-size:cover;
                                        color: white;
                                        background-color:rgb(255, 254, 245);
                                        padding: 0 2.784% 0 6.728%;
                                        font-size:40pt;
                                        font-family:'Gen';
                                        min-height: 90px;
                                        max-height: 130px;
                                    ">
                                    {{ item.disease }}
                                </button>
                            </h2>
                            <div
                                :id="'a'+index"
                                class="accordion-collapse collapse"
                                style="padding-left:3.18%;"
                            >
                                <div class="accordion-body" style="text-align:justify;background-color:white;border-radius:0 0 10px 10px;">
                                    <p style="font-family: 'jf'; font-size: 25pt; margin: 0;">{{ item.explanation }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div style="position:relative;">
                    <div style="padding: 8.662% 24.652% 0% 23.061%;">
                        <div style="position:relative;z-index: 5;">
                            <img :src="Image.TitleHealthSupplyStation" style="position:absolute;"><!--健康補給站-->
                        </div>
                    </div>
                    
                    <div class="card" style="margin: 10.735% 7.375% 0 6.861%;">
                        <div>
                            <!--補給站-方法標題-->
                            <p style="text-align:center; margin: 0; font-family: 'Gen'; font-size: 45pt; color: rgb(55,136,148); padding: 6.461% 0 0 0;">
                                {{ Solution.SolutionTitle }}
                            </p>
                            <!--補給站-材料-->
                            <div class="row" style="padding: 0.193% 0 0 0;">
                                <div style="padding: 0% 80.404% 0% 7.47%">
                                    <img src="/img/TestResultsImage/icon/title_material.svg" class="rounded float-start">
                                </div>
                                <div style="padding: 0% 6.464% 0% 8.080%;">
                                    <p style="text-align:justify; font-family: 'jf'; font-size: 25pt;">{{ Solution.SolutionMaterial }}</p>
                                </div>
                            </div>
                            <!--補給站-方法-->
                            <div class="row">
                                <div style="padding: 0% 80.404% 0% 7.47%">
                                    <img src="/img/TestResultsImage/icon/title_method.svg" class="rounded float-start">
                                </div>
                                <div style="padding: 0% 6.464% 0% 8.080%;">
                                    <p style="text-align:justify; font-family: 'jf'; font-size: 25pt;">{{ Solution.SolutionMethod }}</p>
                                </div>
                            </div>
                            <!--補給站-其他-->
                            <div v-if="ShowSolutionOther" class="row">
                                <!--其他icon-->
                                <div style="padding: 0% 80.404% 0% 7.47%">
                                    <div>
                                        <img src="/img/TestResultsImage/icon/title_other.svg" class="rounded float-start">
                                    </div>
                                </div>
                                <!--提醒icon與內文 -->
                                <div v-show="ShowWarn">
                                    <div style="padding: 0% 85.858% 0% 8.08%;">
                                        <img src="/img/TestResultsImage/icon/icon_remind.svg" class="rounded float-start">
                                    </div>
                                    <div style="padding: 0% 6.464% 0% 14.949%;">
                                        <p style="text-align:justify; font-family: 'jf'; font-size: 25pt;">{{ SolutionOther.Warn }}</p>
                                    </div>
                                </div>
                                <!--執行icon與內文-->
                                <div v-show="ShowGood">
                                    <div style="padding: 0% 85.858% 0% 8.08%;">
                                        <img src="/img/TestResultsImage/icon/icon_good.svg" class="rounded float-start">  
                                    </div>
                                    <div style="padding: 0% 6.464% 0% 14.949%;">
                                        <p style="text-align:justify; font-family: 'jf'; font-size: 25pt;">{{ SolutionOther.Good }}</p>
                                    </div>
                                </div>
                                <!--禁忌icon與內文-->
                                <div v-show="ShowBad">
                                    <div style="padding: 0% 85.858% 0% 8.08%;">
                                        <img src="/img/TestResultsImage/icon/icon_bad.svg" class="rounded float-start">
                                    </div>
                                    <div style="padding: 0% 6.464% 0% 14.949%;">
                                        <p style="text-align:justify; font-family: 'jf'; font-size: 25pt;">{{ SolutionOther.Bad }}</p>
                                    </div>
                                </div>
                                <!--警告icon與內文-->
                                <div v-show="ShowRemind">
                                    <div style="padding: 0% 85.858% 0% 8.08%;">
                                        <img src="/img/TestResultsImage/icon/icon_information.svg" class="rounded float-start">
                                    </div>
                                    <div style="padding: 0% 6.464% 0% 14.949%;">
                                        <p style="text-align:justify; font-family: 'jf'; font-size: 25pt;">{{ SolutionOther.Remind }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="position:relative;margin:0.772% 0 0 0;">
                    <img :src="Image.BottomBackgroundCard" style="position:absolute">
                </div>
                <div style="position:relative;margin:15.437% 24.528% 0 23.156%;">
                    <img :src="Image.ButtomRetest" style="position:absolute;cursor:pointer;" @click="GoIndex">
                </div>
                <div style="position:relative;margin:33.962% 39.108% 50% 37.735%;">
                    <a href="https://forms.gle/aSddUNJGnQzZmQDD7">
                        <img :src="Image.ButtomUseFeedback" style="position:absolute;cursor:pointer;">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserInfoApiHelper from "../script/Utility/UserInfoApiHelper";
export default {
    mounted() {
        const params = {
            UserId: this.$route.params.source
        };
        UserInfoApiHelper.GetUserTestRecord(params).then((response) => {
            this.UserName = response.data.UserName;
            this.UserGender = response.data.UserGender;
            this.FaceTitle = response.data.FaceTitle;
            this.Personality = response.data.Personality;
            this.Solution = response.data.Solution;
            this.Disease = response.data.Disease;
            if(response.data.SolutionOther) {
                this.SolutionOther = response.data.SolutionOther;
                this.ShowSolutionOther = true;
            }
            else this.ShowSolutionOther = false;
            if(response.data.SolutionOther.Warn) {
                this.ShowWarn = true;
            } else {
                this.ShowWarn = false;
            }
            if(response.data.SolutionOther.Remind) {
                this.ShowRemind = true;
            } else {
                this.ShowRemind = false;
            }
            if(response.data.SolutionOther.Good) {
                this.ShowGood = true;
            } else {
                this.ShowGood = false;
            }
            if(response.data.SolutionOther.Bad) {
                this.ShowBad = true;
            } else {
                this.ShowBad = false;
            }
            this.GetFaceImage(response.data.FaceShape,response.data.FaceColor);
        })
    },
    data() {
        return {
            ShowSolutionOther: false,
            ShowWarn: false,
            ShowRemind: false,
            ShowGood: false,
            ShowBad: false,
            UserName: '',
            UserGender: '',
            FaceTitle: '',
            Personality: '',
            Solution: {},
            SolutionOther: {},
            Disease: {},
            Image: {
                mirror: '',
                TitleHealthWarning: '/img/TestResultsImage/other/title_health_warning.svg',
                TopBackgroundCard: '/img/TestResultsImage/other/bg_top.svg',
                BottomBackgroundCard: '/img/TestResultsImage/other/bg_bottom.svg',
                TitleBackgroundImg: '/img/TestResultsImage/other/titile_TestResults.svg',
                TitleHealthSupplyStation: '/img/TestResultsImage/other/title_health_supply_station.svg',
                ButtomRetest: '/img/TestResultsImage/other/buttom_retest.svg',
                ButtomUseFeedback: '/img/TestResultsImage/other/buttom_use_feedback.svg'
            },
        }
    },
    methods: {
        GetFaceImage(FaceShape,FaceColor) {
            if(this.UserGender === "M") {
                switch(FaceShape) {
                    case 'square':
                        this.Image.mirror = '/img/FaceSelect/square/img_mal_square.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square.svg';
                                    break;
                            }
                        }
                        break;
                    case 'triangle':
                        this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'circle':
                        this.Image.mirror = '/img/FaceSelect/circle/img_male_circle.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'egg':
                        this.Image.mirror = '/img/FaceSelect/egg/img_male_egg.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg.svg';
                                    break;
                            }
                        }
                        break;
                }
            } else {
                switch(FaceShape) {
                    case 'square':
                        this.Image.mirror = '/img/FaceSelect/square/img_femal_square.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square.svg';
                                    break;
                            }
                        }
                        break;
                    case 'triangle':
                        this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'circle':
                        this.Image.mirror = '/img/FaceSelect/circle/img_female_circle.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'egg':
                        this.Image.mirror = '/img/FaceSelect/egg/img_female_egg.svg';
                        if(FaceColor) {
                            switch(FaceColor) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg.svg';
                                    break;
                            }
                        }
                        break;
                }
            }
        },
        GoIndex() {
            this.$router.push({name: 'Home'});
        }
    },
}
</script>

<style scoped>
    @media (min-width: 320px) and (max-width: 1280px){
        .FaceTitleText{
            margin: 1.5%;
        }
    }

    @media (min-width: 1280px){
        .FaceTitleText{
            margin: 3.444% auto 0;
        }
    }
    .accordion-button:not(.collapsed)::after{
        background-image: url('/img/TestResultsImage/icon/fold_icon.svg');
        transform: rotate(0.5turn);
    }
    .accordion-button::after{
        background-image: url('/img/TestResultsImage/icon/fold_icon.svg');
    }
</style>