<template>
    <div style="width:90%;margin:0 auto;">
        <div style="max-width:1166px;position: relative;margin:0 auto;">
            <div v-if="FaceSelectShow.FaceShape || FaceSelectShow.FaceColor" @click="CloseFaceSelect(FaceSelectShow)" :style="{'position':'absolute','width': '100%','height': HeaderHeight,'top':'0','left':'0','z-index': '1'}"></div>
            <div style="max-width:1166px;margin-top:100px;margin-bottom:84px;">
                <div style="position:relative">
                    <img id="header" :src="Image.BackgroundCard" style="position:absolute;">
                </div>
                <div style="margin:0 8.233% 0 6.861%;padding-top:23.156%;">
                    <div style="position:relative;">
                        <img style="position:absolute;" :src="Image.mirror">
                    </div>
                </div>
                <div style="margin:0 113px 0 97px;padding-top:100.171%;">
                    <div style="position:relative">
                        <img style="position:absolute;" :src="Image.title">
                    </div>
                </div>
                <div>
                    <div style="margin:10.291% 56.003% 0 16.037%;position:relative;">
                        <div>
                            <img style="position:absolute;cursor:pointer;" :src="Image.FaceShapeSelectBackgroundImg" @click="OpenFaceShapeSelect">
                        </div>
                        <div v-show="FaceSelectShow.FaceShape" style="position:absolute;width:100%;padding-top:26.381%;">
                            <ul style="list-style:none;padding:0 20px 0 0 !important;background-color:white;position:relative;z-index:2;">
                                <li style="list-style:none;padding-left:24px;margin-top:8px !important;cursor:pointer;font-size:50px;font-family:jf;" @click="GetFaceShapeValue(item.value)" v-for="(item, index) in FacialShape" :key="index">
                                    {{ item.label }}
                                    <hr style="display:block;border: 1px solid rgba(200, 200, 200, 0.42); margin:8px 0 0 -4px !important">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin:0 17.409% 0 54.631%;position: relative;">
                        <div>
                            <img style="position:absolute;cursor:pointer;" :src="Image.FaceColorSelectBackgroundImg" @click="OpenFaceColorSelect">
                        </div>
                        <div v-show="FaceSelectShow.FaceColor" style="position:absolute;width:100%;padding-top:26.381%;">
                            <ul style="list-style:none;padding:0 20px 0 0 !important;background-color:white;position:relative;z-index:2;">
                                <li style="list-style:none;padding-left:24px;margin-top:8px !important;cursor:pointer;font-size:50px;font-family:jf;" @click="GetFaceColorValue(item.value)" v-for="(item, index) in FacialColor" :key="index">
                                    {{ item.label }}
                                    <hr style="display:block;border: 1px solid rgba(200, 200, 200, 0.42); margin:8px 0 0 -4px !important">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="margin:31.217% 0 0 0;padding:0 24.528% 0 23.156%">
                    <div style="position:relative;">
                        <img :style="{ 'position':'absolute','cursor':ButtonCursor }" :src="Image.NextButton" @click="NextPage">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserProfileApiHelper from '../script/Utility/UserProfileApiHelper';
export default {
    mounted() {
        this.GetHeaderHeight();
        const params = {
            UserId: this.$route.params.source,
        };
        UserProfileApiHelper.GetUserProfileGender(params).then((response) =>{
            this.Gender = response.data.Gender;
        })
    },
    data() {
        return {
            HeaderHeight: '',
            Gender: '',
            FaceData: {
                shape: '',
                color: '',
            },
            Image: {
                mirror: '/img/FaceSelect/other/img_mirror.svg',
                title: '/img/FaceSelect/other/img_select_title.svg',
                BackgroundCard: '/img/FaceSelect/other/img_background_card.svg',
                FaceShapeSelectBackgroundImg: '/img/FaceSelect/other/img_combo_chose.svg',
                FaceColorSelectBackgroundImg: '/img/FaceSelect/other/img_combo_chose.svg',
                NextButton: '/img/FaceSelect/other/img_button_click_no.svg',
            },
            FacialShape: [
                {
                    label: '方形',
                    value: 'square',
                },
                {
                    label: '三角形',
                    value: 'triangle',
                },
                {
                    label: '圓形',
                    value: 'circle',
                },
                {
                    label: '雞蛋型',
                    value: 'egg',
                }
            ],
            FacialColor: [
                {
                    label: '正常',
                    value: 'normal',
                },
                {
                    label: '泛紅',
                    value: 'red',
                },
                {
                    label: '泛黑',
                    value: 'black',
                },
                {
                    label: '泛紫',
                    value: 'purple',
                },
                {
                    label: '泛黃',
                    value: 'yellow',
                },
                {
                    label: '泛白',
                    value: 'white',
                },
                {
                    label: '泛青',
                    value: 'green',
                },
            ],
            FaceSelectShow: {
                FaceShape: false,
                FaceColor: false,
            },
            ButtonCursor: '',
        }
    },
    methods: {
        NextPage() {
            const params = {
                UserId: this.$route.params.source,
                FaceShape: this.FaceData.shape,
                FaceColor: this.FaceData.color
            };
            loading()
            if(this.FaceData.shape && this.FaceData.color) {
                UserProfileApiHelper.UserFaceDataBasicInfo(params).then((response) => {
                    if(response.statusCode == 200) {
                        loading(false);
                        this.$router.push({name: 'TestResults'});
                    } else {
                        loading(false);
                        alert("系統忙碌中，請稍後在試。");
                    }
                });
            }
            else {
                loading(false);
                alert('請選擇臉型或臉色');
            }
        },
        GetHeaderHeight() {
            this.HeaderHeight = header.offsetHeight + 100 + 'px';
        },
        OpenFaceShapeSelect() {
            this.GetHeaderHeight();
            this.FaceSelectShow.FaceShape = !this.FaceSelectShow.FaceShape;
            if(!this.FaceData.shape) {
                this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/other/img_combo_chose_selected.svg';
            }
        },
        GetFaceShapeValue(item) {
            this.FaceData.shape = item;
            this.FaceSelectShow.FaceShape = false;
        },
        OpenFaceColorSelect() {
            this.GetHeaderHeight();
            this.FaceSelectShow.FaceColor = !this.FaceSelectShow.FaceColor;
            if(!this.FaceData.color) {
                this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/other/img_combo_chose_selected.svg';
            }
        },
        GetFaceColorValue(item) {
            this.FaceData.color = item;
            this.FaceSelectShow.FaceColor = false;
        },
        CloseFaceSelect(value) {
            if(value.FaceShape) {
                this.FaceSelectShow.FaceShape = false;
                if(!this.FaceData.shape){
                    this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/other/img_combo_chose.svg';
                }
            } else {
                this.FaceSelectShow.FaceColor = false;
                if(!this.FaceData.color){
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/other/img_combo_chose.svg';
                }
            }
        },
    },
    watch: {
        "FaceData.shape": function(value) {
            //setCookie("FaceShape", value);
            if(this.Gender === "M") {
                switch(value) {
                    case 'square':
                        this.Image.mirror = '/img/FaceSelect/square/img_mal_square.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_square_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/square/img_mal_square.svg';
                                    break;
                            }
                        }
                        break;
                    case 'triangle':
                        this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_triangle_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'circle':
                        this.Image.mirror = '/img/FaceSelect/circle/img_male_circle.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_circle_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_male_circle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'egg':
                        this.Image.mirror = '/img/FaceSelect/egg/img_male_egg.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_egg_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_male_egg.svg';
                                    break;
                            }
                        }
                        break;
                }
            } else {
                switch(value) {
                    case 'square':
                        this.Image.mirror = '/img/FaceSelect/square/img_female_square.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_square_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/square/img_female_square.svg';
                                    break;
                            }
                        }
                        break;
                    case 'triangle':
                        this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_triangle_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'circle':
                        this.Image.mirror = '/img/FaceSelect/circle/img_female_circle.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_circle_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/circle/img_female_circle.svg';
                                    break;
                            }
                        }
                        break;
                    case 'egg':
                        this.Image.mirror = '/img/FaceSelect/egg/img_female_egg.svg';
                        this.Image.FaceShapeSelectBackgroundImg = '/img/FaceSelect/img_combo_shape/img_combo_shape_egg_selected.svg';
                        if(this.FaceData.color) {
                            switch(this.FaceData.color) {
                                case 'red':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_red.svg';
                                    break;
                                case 'black':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_black.svg';
                                    break;
                                case 'purple':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_purple.svg';
                                    break;
                                case 'yellow':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_yellow.svg';
                                    break;
                                case 'white':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_white.svg';
                                    break;
                                case 'green':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_green.svg';
                                    break;
                                case 'normal':
                                    this.Image.mirror = '/img/FaceSelect/egg/img_female_egg.svg';
                                    break;
                            }
                        }
                        break;
                }
            }
            if(this.FaceData.color) {
                this.Image.NextButton = '/img/FaceSelect/other/img_button_click_yes.svg'
                this.ButtonCursor = 'pointer';
            }
        },
        "FaceData.color": function(value) {
            //setCookie("FaceColor", value);
            if(this.Gender === "M") {
                switch (this.FaceData.shape) {
                    case 'square':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/square/img_mal_square.svg';
                                break;
                        }
                        break;
                    case 'triangle':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_male_triangle.svg';
                                break;
                        }
                        break;
                    case 'circle':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/circle/img_male_circle.svg';
                                break;
                        }
                        break;
                    case 'egg':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/egg/img_male_egg.svg';
                                break;
                        }
                        break;
                } 
            } else {
                switch (this.FaceData.shape) {
                    case 'square':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/square/img_female_square.svg';
                                break;
                        }
                        break;
                    case 'triangle':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/triangle/img_female_triangle.svg';
                                break;
                        }
                        break;
                    case 'circle':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/circle/img_female_circle.svg';
                                break;
                        }
                        break;
                    case 'egg':
                        switch(value) {
                            case 'red':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_red.svg';
                                break;
                            case 'black':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_black.svg';
                                break;
                            case 'purple':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_purple.svg';
                                break;
                            case 'yellow':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_yellow.svg';
                                break;
                            case 'white':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_white.svg';
                                break;
                            case 'green':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg_green.svg';
                                break;
                            case 'normal':
                                this.Image.mirror = '/img/FaceSelect/egg/img_female_egg.svg';
                                break;
                        }
                        break;
                }
            }
            switch(value) {
                case 'red':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_red_selected.svg';
                    break;
                case 'black':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_black_selected.svg';
                    break;
                case 'purple':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_purple_selected.svg';
                    break;
                case 'yellow':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_yellow_selected.svg';
                    break;
                case 'white':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_white_selected.svg';
                    break;
                case 'green':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_green_selected.svg';
                    break;
                case 'normal':
                    this.Image.FaceColorSelectBackgroundImg = '/img/FaceSelect/img_combo_color/img_combo_color_normal_selected.svg';
                    break;
            }
            if(this.FaceData.shape) {
                this.Image.NextButton = '/img/FaceSelect/other/img_button_click_yes.svg'
                this.ButtonCursor = 'pointer';
            }
        }
    }
}
</script>