import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";

const Api = {
    "TestRecord": "/api/UserInfo/FaceShapeTest/GetUserTestRecord",
}
export default class UserLineApiHelper {
    
    public static GetUserTestRecord(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, Api.TestRecord, params);
    }
}