import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";

const LineApi = {
    "Login": "/api/UserLogin/Line/Login",
}
export default class UserLineApiHelper {
    
    public static UserLineLogin() {
        return ApiHelper.callSelf(ApiMethods.GET, LineApi.Login);
    }
}