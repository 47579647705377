<template>
    <div style="width:90%;margin:0 auto;">
        <div style="max-width:1166px;position: relative;margin:0 auto;">
            <div style="max-width:1166px;margin-top:100px;margin-bottom:84px;">
                <div style="position:relative;">
                    <img :src="Image.BackgroundCard" style="position:absolute;">
                </div>
                <div style="padding:26.586% 33.962% 0 32.59%;">
                    <div style="position:relative;">
                        <img :src="Image.SexTitle" style="position:absolute;">
                    </div>
                </div>
                <div class="row" style="margin:0;padding:14.493% 7.718% 0 6.346%">
                    <div class="col" style="position:relative;margin-right:3.992%;padding:0;">
                        <img 
                            @click="SelectGender('M')" 
                            :src="Image.Male"
                            style="cursor:pointer;position:absolute;"
                        >
                    </div>
                    <div class="col" style="position:relative;margin-left:3.992%;padding:0;">
                        <img 
                            @click="SelectGender('F')" 
                            :src="Image.Female"
                            style="cursor:pointer;position:absolute;"
                        >
                    </div>
                </div>
                <div style="margin:53.259% 33.962% 0 32.59%;">
                    <div style="position:relative;">
                        <img :src="Image.AgeTitle" style="position:absolute;">
                    </div>
                </div>
                <div class="row" style="margin:0;padding: 14.408% 0 0 0;">
                    <div class="col" style="padding:0;">
                        <div style="position:relative;padding:3.576% 0 0 66.893%">
                            <!-- <button style="width:100%;" @click="() => this.Age -= 1">+</button> -->
                            <img :src="Image.DecreaseButton" style="position:absolute;width:30%;" @mouseover="DecreaseButtonMouseover" @mouseout="DecreaseButtonMouseout" @click="AgeCalculate('Decrease')">
                        </div>
                    </div>
                    <div style="padding:0;flex:0 0 auto;width: 40%;">
                        <div style="position:relative;max-width:466px;width:100%;">
                            <input
                                v-model.number="Age"
                                type="number"
                                placeholder="請輸入您的年齡" 
                            >
                        </div>
                    </div>
                    <div class="col" style="padding:0;">
                        <div style="position:relative;padding:3.576% 0 0 9.147%">
                            <!-- <button style="width:100%;" @click="() => this.Age += 1">+</button> -->
                            <img :src="Image.IncreaseButton" style="position:absolute;width:30%" @mouseover="IncreaseButtonMouseover" @mouseout="IncreaseButtonMouseout" @click="AgeCalculate('Increase')">
                        </div>
                    </div>
                </div>
                <div style="margin:45.797% 0 0 0;padding:0 24.528% 0 23.156%">
                    <div style="position:relative;">
                        <img :src="Image.NextButton" :style="{'cursor': ButtonCursor}" @click="NextPage">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserProfileApiHelper from '../script/Utility/UserProfileApiHelper';
    export default {
        mounted() {
            const params = {
                UserId: this.$route.params.source,
            };
            UserProfileApiHelper.GetUserFaceTestBasicinformation(params).then((response) => {
                if(response.data.Gender) {
                    this.gender = response.data.Gender;
                }
            });
        },
        data () {
            return {
                Age: 30,
                gender: "",
                ButtonCursor: '',
                Image: {
                    Male: "/img/BasicInformationImage/btn_sex_male.svg",
                    Female: "/img/BasicInformationImage/btn_sex_female.svg",
                    SexTitle: "/img/BasicInformationImage/bar_title_sex.svg",
                    AgeTitle: "/img/BasicInformationImage/bar_title_age.svg",
                    NextButton:"/img/BasicInformationImage/btn_nextstep.svg",
                    BackgroundCard: '/img/BasicInformationImage/bg.svg',
                    IncreaseButton: '/img/BasicInformationImage/btn_sex_increase.svg',
                    DecreaseButton: '/img/BasicInformationImage/btn_sex_decrease.svg',
                },
            }
        },
        methods: {
            NextPage() {
                loading();
                if(this.Age < 0 || this.Age > 100) {
                    alert("請輸入真實年齡");
                    this.Age = '';
                    loading(false);
                    return;
                }
                if(this.gender && this.Age) {
                    const params = {
                        Age: this.Age,
                        Gender: this.gender,
                        UserId: this.$route.params.source
                    };
                    UserProfileApiHelper.UserBasicInfo(params).then((response) => {
                        if(response.statusCode == 200) {
                            loading(false);
                            this.$router.push({name: 'FacialDetection'})
                        };
                    });
                }
                else {
                    loading(false);
                    alert('請選擇性別');
                }
            },
            SelectGender(value) {
                this.gender = value;
            },
            AgeCalculate(value) {
                switch (value) {
                    case 'Decrease':
                        this.Age--;
                        this.Image.DecreaseButton = '/img/BasicInformationImage/btn_sex_decrease_pre.svg';
                        this.Image.DecreaseButton = '/img/BasicInformationImage/btn_sex_decrease.svg';
                        break;
                    case 'Increase':
                        this.Age++;
                        this.Image.IncreaseButton = '/img/BasicInformationImage/btn_sex_increase_pre.svg';
                        this.Image.IncreaseButton = '/img/BasicInformationImage/btn_sex_increase.svg';
                        break;
                }
            },
            DecreaseButtonMouseover() {
                this.Image.DecreaseButton = '/img/BasicInformationImage/btn_sex_decrease_pre.svg';
            },
            DecreaseButtonMouseout() {
                this.Image.DecreaseButton = '/img/BasicInformationImage/btn_sex_decrease.svg';
            },
            IncreaseButtonMouseover() {
                this.Image.IncreaseButton = '/img/BasicInformationImage/btn_sex_increase_pre.svg';
            },
            IncreaseButtonMouseout() {
                this.Image.IncreaseButton = '/img/BasicInformationImage/btn_sex_increase.svg';
            }
        },
        watch: {
            "gender": function(val){
                switch (val) {
                    case 'M':
                        this.Image.Male = "/img/BasicInformationImage/btn_sex_male_selected.svg";
                        this.Image.Female = "/img/BasicInformationImage/btn_sex_female.svg";
                        break;
                    case 'F':
                        this.Image.Male = "/img/BasicInformationImage/btn_sex_male.svg";
                        this.Image.Female = "/img/BasicInformationImage/btn_sex_female_selected.svg";
                        break;
                }
                this.ButtonCursor = 'pointer';
                this.Image.NextButton = "/img/BasicInformationImage/btn_nextstep_selected.svg";
            },
        },
    }
</script>

<style scoped>
    @media (min-width: 320px) and (max-width: 860px){
        input {
            font-size:60px;
        }
    }
    @media (min-width: 861px) and (max-width: 1149px){
        input {
            font-size:70px;
        }
    }
    @media (min-width: 1150px){
        input{
            font-size:80px;
        }
    }
    input {
        max-height: 136px;
        width:100%;
        position:absolute;
        font-family: 'jf';
        text-align:center;
        outline:none;
        border:none;
        background-repeat:no-repeat;
        border:rgb(95, 185, 205) solid 5px;
        border-radius:5pt;
        z-index:2;
    }
    input:focus {
        border:rgb(55, 136, 148) solid 5px;
    }
    /* Chrome, Safari, Edge, Opera */
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }


    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
</style>