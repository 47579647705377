import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";

const UserProfileApi = {
    "GetUserFaceTestBasicinformation": "/api/UserProfile/GetUserFaceTestBasicinformation",
    "GetUserProfileGender": "/api/UserProfile/GetUserProfileGender",
    "UserBasicInfo": "/api/UserProfile/UserBasicInfo",
    "UserFaceDataBasicInfo": "/api/UserProfile/UserFaceDataBasicInfo",
    "UserProfileUpDate": "/api/UserProfile/UserProfileUpDate",
}
export default class UserProfileApiHelper {
    public static GetUserFaceTestBasicinformation(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, UserProfileApi.GetUserFaceTestBasicinformation, params);
    }

    public static GetUserProfileGender(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, UserProfileApi.GetUserProfileGender, params);
    }

    public static UserBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, UserProfileApi.UserBasicInfo, params);
    }

    public static UserFaceDataBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, UserProfileApi.UserFaceDataBasicInfo, params);
    }

    public static UserProfileUpDate(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, UserProfileApi.UserProfileUpDate, params);
    }
}