var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticStyle:{"max-width":"1166px","position":"relative","left":"50%","transform":"translate(-50%, 0)"}},[_c('div',{staticStyle:{"max-width":"1166px","margin-top":"100px","margin-bottom":"84px","background-image":"url(/img/BasicInformationImage/bg.svg)","background-repeat":"no-repeat","background-size":"cover"}},[_c('div',{staticClass:"row justify-content-around"},[_c('div',{staticStyle:{"margin":"150px 0px 0px 0px","width":"90%"}},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('div',{staticClass:"row",staticStyle:{"position":"relative","margin":"420px 0px 0px 0px"}},[_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',{staticClass:"col",staticStyle:{"margin":"20px 0px 0px 0px"}},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"exampleInputPassword1","disabled":"disabled"},domProps:{"value":_vm.random}})]),_vm._v(" "),_c('div',{staticClass:"col",staticStyle:{"margin":"20px 0px 0px 0px"}},[_c('button',{staticClass:"btn btn-primary"},[_c('span',{staticClass:"glyphicon glyphicon-refresh",on:{"click":_vm.captcha}},[_vm._v("重新產生")])])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",staticStyle:{"margin":"100px 0px 30px 0px"},attrs:{"type":"submit"},on:{"click":_vm.modify}},[_vm._v("修改")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"position":"relative"}},[_c('label',{staticClass:"form-label",staticStyle:{"position":"absolute"},attrs:{"for":"exampleInputEmail1"}},[_vm._v("Email 電子信箱")]),_vm._v(" "),_c('input',{staticClass:"form-control",staticStyle:{"position":"absolute","margin":"40px 0px 0px 0px"},attrs:{"type":"email","id":"exampleInputEmail1","aria-describedby":"emailHelp"}}),_vm._v(" "),_c('div',{staticClass:"form-text",staticStyle:{"position":"absolute","margin":"90px 0px 0px 0px"},attrs:{"id":"emailHelp"}},[_vm._v("我們絕不會與其他任何人分享您的電子郵件。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"position":"relative"}},[_c('label',{staticClass:"form-label",staticStyle:{"position":"absolute","margin":"120px 0px 0px 0px"},attrs:{"for":"exampleInputPassword1","placeholder":"Password"}},[_vm._v("手機")]),_vm._v(" "),_c('input',{staticClass:"form-control",staticStyle:{"position":"absolute","margin":"160px 0px 0px 0px"},attrs:{"type":"password","id":"exampleInputPassword1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"position":"relative"}},[_c('label',{staticClass:"form-label",staticStyle:{"position":"absolute","margin":"220px 0px 0px 0px"},attrs:{"for":"exampleInputPassword1","placeholder":"Password"}},[_vm._v("密碼")]),_vm._v(" "),_c('input',{staticClass:"form-control",staticStyle:{"position":"absolute","margin":"260px 0px 0px 0px"},attrs:{"type":"password","id":"exampleInputPassword1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3",staticStyle:{"position":"relative"}},[_c('label',{staticClass:"form-label",staticStyle:{"position":"absolute","margin":"320px 0px 0px 0px"},attrs:{"for":"exampleInputPassword1","placeholder":"Confirm Password"}},[_vm._v("確認密碼")]),_vm._v(" "),_c('input',{staticClass:"form-control",staticStyle:{"position":"absolute","margin":"360px 0px 0px 0px"},attrs:{"type":"password","id":"exampleInputPassword1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",staticStyle:{"margin":"20px 0px 0px 0px"}},[_c('label',{staticClass:"form-label",attrs:{"for":"exampleInputPassword1","placeholder":"Confirm Password"}},[_vm._v("驗證碼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",staticStyle:{"margin":"20px 0px 0px 0px"}},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"exampleInputPassword1"}})])
}]

export { render, staticRenderFns }