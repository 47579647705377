<template>
    <div class="container">
        <div style="max-width:1166px; position: relative; left: 50%;transform: translate(-50%, 0);">
            <div style="max-width: 1166px; margin-top:100px; margin-bottom:84px;background-image: url(/img/BasicInformationImage/bg.svg); background-repeat:no-repeat; background-size:cover;">
                <div class="row justify-content-around">
                    <div style="margin: 150px 0px 0px 0px; width: 90%;">
                        <div class="mb-3" style="position:relative">
                            <label for="exampleInputEmail1" class="form-label" style="position:absolute;">Email 電子信箱</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style="position:absolute; margin: 40px 0px 0px 0px;">
                            <div id="emailHelp" class="form-text" style="position:absolute; margin: 90px 0px 0px 0px;">我們絕不會與其他任何人分享您的電子郵件。</div>
                        </div>
                        <div class="mb-3" style="position:relative">
                            <label for="exampleInputPassword1" class="form-label" placeholder="Password" style="position:absolute; margin: 120px 0px 0px 0px;">手機</label>
                            <input type="password" class="form-control" id="exampleInputPassword1" style="position:absolute; margin: 160px 0px 0px 0px;">
                        </div>
                         <div class="mb-3" style="position:relative">
                            <label for="exampleInputPassword1" class="form-label" placeholder="Password" style="position:absolute; margin: 220px 0px 0px 0px;">密碼</label>
                            <input type="password" class="form-control" id="exampleInputPassword1" style="position:absolute; margin: 260px 0px 0px 0px;">
                        </div>
                        <div class="mb-3" style="position:relative">
                            <label for="exampleInputPassword1" class="form-label" placeholder="Confirm Password" style="position:absolute; margin: 320px 0px 0px 0px;">確認密碼</label>
                            <input type="password" class="form-control" id="exampleInputPassword1" style="position:absolute; margin: 360px 0px 0px 0px;">
                        </div>

                        <div class="row" style="position:relative; margin: 420px 0px 0px 0px;">
                            <div class="col" style="margin: 20px 0px 0px 0px;">
                                <label for="exampleInputPassword1" class="form-label" placeholder="Confirm Password">驗證碼</label>
                            </div>
                            <div class="col"  style="margin: 20px 0px 0px 0px;">
                                <input type="text" class="form-control" id="exampleInputPassword1">
                            </div>
                            <div class="col"  style="margin: 20px 0px 0px 0px;">
                                <input type="text" class="form-control" id="exampleInputPassword1" disabled="disabled" :value= random>
                            </div>
                            <div class="col"  style="margin: 20px 0px 0px 0px;">
                                <button class="btn btn-primary">
                                    <span class="glyphicon glyphicon-refresh" @click="captcha">重新產生</span> 
                                </button>
                            </div>
                        </div>
                        
                        <button type="submit" class="btn btn-primary" style="margin: 100px 0px 30px 0px;" @click="modify">修改</button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import UserProfileApiHelper from "../script/Utility/UserProfileApiHelper";
    export default {
        data() {
            return {
                random: "QKgJ",
            }
        },
        methods: {
            
            captcha(){
                const params = {
                    a: "123",
                };
                this.random = "";
                UserProfileApiHelper.UserProfileUpDate(params).then((response) => {
                    this.random = response.data.a;
                });
            },
            modify(){
                UserProfileApiHelper.UserProfileUpDate(params).then((response) => {

                });
            },
        }
    }
</script>
<style scoped>
    .card{
        border: 0px;
    }
    
</style>
