<template>
    <div style="width:90%;margin:0 auto;">
        <div style="max-width:1166px;position: relative;margin:0 auto;">
            <div style="max-width:1166px;margin-top:100px;margin-bottom: 100px;">
                <div style="position:relative;">
                    <img :src="Image.BackgroundCard" style="position:absolute;">
                </div>
                <div style="padding:155.403% 27.407% 0 27.407%;">
                    <div style="position:relative;">
                        <img :src="Image.Start_Btn" style="position:absolute;cursor:pointer;" @click="Login">
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserLineApiHelper from "../script/Utility/UserLineApiHelper";
    export default {
        data() {
            return {
                Image: {
                    BackgroundCard: '/img/HomeImage/title_bg.svg',
                    Start_Btn: '/img/HomeImage/title_start_btn.svg',
                },
            }
        },
        methods: {
            Login(){
                UserLineApiHelper.UserLineLogin()
                .then((response) => {
                    window.location.href = response.data.url;
                })
            }
        }
    }
</script>
<style scoped>
    .card{
        border: 0px;
    }
</style>