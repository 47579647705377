// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../public/fonts/GenJyuuGothic-Monospace-Bold-2.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../public/fonts/CorporateLogoRounded.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../public/fonts/jf-openhuninn-1.1.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Gen;font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:Cor;font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-family:jf;font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}", "",{"version":3,"sources":["webpack://./resources/css/fonts.css"],"names":[],"mappings":"AAAA,WACI,eAAkB,CAGlB,iBAAkB,CADlB,eAAmB,CADnB,8DAGJ,CACA,WACI,eAAkB,CAGlB,iBAAkB,CADlB,eAAmB,CADnB,8DAGJ,CACA,WACI,cAAiB,CAGjB,iBAAkB,CADlB,eAAmB,CADnB,8DAGJ","sourcesContent":["@font-face{\n    font-family: \"Gen\";\n    src: url('../../public/fonts/GenJyuuGothic-Monospace-Bold-2.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n@font-face{\n    font-family: \"Cor\";\n    src: url('../../public/fonts/CorporateLogoRounded.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n@font-face{\n    font-family: \"jf\";\n    src: url('../../public/fonts/jf-openhuninn-1.1.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
